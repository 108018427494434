import Image from 'next/image';

function Item(props) {
  return (
    <a
      href={props.href}
      rel="noreferrer"
      area-label={props.href}
      className="block font-[400] leading-[22px] text-[14px] box-border md:text-[16px] text-white hover:text-green md:mb-[10px] mb-[5px] pr-[10px]"
    >
      {props.children}
    </a>
  );
}
function Heading(props) {
  return <p className={'text-14 text-green uppercase font-bold mb-20 ' + (props.className || '')}>{props.children}</p>;
}

function Footer(props) {
  return (
    <div className="bg-black pt-23 md:pt-30 relative footer">
      <div className="container">
        <div className="md:hidden">
          <div className="flex items-center mb-[17px] gap-[3px]">
            <a
              aria-label="Left align"
              href="https://www.facebook.com/weprodigi/"
              rel="noreferrer"
              area-label="https://www.facebook.com/weprodigi"
              target="_blank"
            >
              <span className="icon icon-fb-green w-[12px] h-[15px] inline-block" />
            </a>
            <a
              aria-label="Left align"
              href="https://www.instagram.com/weprodigi_agency/"
              rel="noreferrer"
              area-label="https://www.instagram.com/weprodigi_agency/"
              target="_blank"
            >
              <span className="icon icon-insta-green w-[24px] h-[14px] inline-block" />
            </a>
            <a
              aria-label="Left align"
              href="https://www.linkedin.com/company/pro.digi/"
              rel="noreferrer"
              area-label="https://www.linkedin.com/company/pro.digi/"
              target="_blank"
            >
              <span className="icon icon-linkedin-green w-[24px] h-[14px] inline-block" />
            </a>
            <a
              aria-label="Left align"
              href="https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg"
              rel="noreferrer"
              area-label="https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg"
              target="_blank"
            >
              <span className="icon icon-youtube-green w-[24px] h-[14px] inline-block" />
            </a>
            <a
              aria-label="Left align"
              href="https://twitter.com/WeProdigi"
              rel="noreferrer"
              area-label="https://twitter.com/WeProdigi"
              target="_blank"
            >
              <span className="icon icon-x-green w-[24px] h-[14px] inline-block" />
            </a>
          </div>
        </div>
        <div className="flex justify-between pb-[22px]">
          <div>
            <p className="uppercase text-[16px] font-[700] leading-[20px] text-[#8DC640] mb-[15px]">About us</p>
            <Item href="https://weprodigi.com/about-us">About Company</Item>
            {props.country !== 'AM' && <Item href="https://weprodigi.com/team">Team</Item>}
            <Item href="https://weprodigi.com/contact-us">Contact Us</Item>
            <Item href="/payment-terms">Payment Terms</Item>

            <a href={'https://weprodigi.com/main/cases'}>
              <p className="uppercase text-[16px] font-[700] leading-[20px] text-[#8DC640] my-[15px]"> Portfolio</p>
            </a>
            <Item href="https://weprodigi.com/main/cases">Cases</Item>
            <Item href="https://weprodigi.com/testimonals">Testimonials</Item>
          </div>
          <div className="md:hidden">
            <p className="uppercase text-[16px] font-[700] leading-[20px] text-[#8DC640] mb-[15px]">Products</p>
            <Item rel="canonical" href="/video-production">
              Video Production
            </Item>

            <Item rel="canonical" href="/social-media-marketing">
              Social Media Marketing
            </Item>
            <Item rel="canonical" href="/seo/professional-seo-services">
              SEO Services
            </Item>
            <Item rel="canonical" href="https://weprodigi.com/paid-advertising-services">
              Paid Advertising Services
            </Item>
            <a rel="canonical" href="/blog">
              <p className="uppercase text-[16px] font-[700] leading-[20px] text-[#8DC640] my-[15px]">Blog</p>
            </a>
          </div>
          <div className="hidden md:block">
            <p className="uppercase text-[16px] font-[700] leading-[20px] text-[#8DC640] mb-[15px]">Production Services</p>
            <Item rel="canonical" href="/video-production">
              Video Production
            </Item>
            <Item rel="canonical" href="/video-production/explainer-videos">
              Explainer Video <br /> Creation
            </Item>
            <Item rel="canonical" href="https://weprodigi.com/main/social-media-fun">
              Branded Game <br /> Creation
            </Item>
            <Item rel="canonical" href="https://weprodigi.com/main/digital-ad-banners">
              Digital Banner <br /> Production
            </Item>
          </div>
          <div className="w-[180px] hidden md:block">
            <p className="uppercase text-[16px] font-[700] leading-[20px] text-[#8DC640] mb-[15px]">Digital marketing services</p>
            <Item rel="canonical" href="/seo/professional-seo-services">
              SEO Services
            </Item>
            <Item rel="canonical" href="https://weprodigi.com/paid-advertising-services">
              Paid Advertising Services
            </Item>
            <Item rel="canonical" href="/social-media-marketing">
              Social Media Marketing
            </Item>
            <a rel="canonical" href="/blog">
              <p className="uppercase text-[16px] font-[700] leading-[20px] text-[#8DC640] my-[15px]">Blog</p>
            </a>
          </div>
          <div className="hidden md:block">
            <div className="hidden lg:block md:py-3 md:leading-none">
              <Image src="/images/logo-light.svg" width={216} height={38} layout="fixed" alt="logo light" />
            </div>
            <div className="lg:hidden">
              <Image src="/images/logo-light.svg" width={173} height={30} layout="fixed" alt="logo light" />
            </div>
            <div className="mb-15 ">
              <a
                role="link"
                href="https://www.facebook.com/weprodigi/"
                rel="noreferrer"
                area-label="https://www.facebook.com/weprodigi"
                target="_blank"
                className="mr-23 md:mr-10 lg:mr-23"
              >
                <span className="icon icon-fb-green w-12 h-15 md:h-15 lg:h-24 inline-block" />
              </a>
              <a
                role="link"
                href="https://www.instagram.com/weprodigi_agency/"
                rel="noreferrer"
                area-label="https://www.instagram.com/weprodigi_agency/"
                target="_blank"
                className="mr-23 md:mr-10 lg:mr-23"
              >
                <span className="icon icon-insta-green w-24 h-15 md:h-15 lg:h-24 inline-block" />
              </a>
              <a
                role="link"
                href="https://www.linkedin.com/company/pro.digi/"
                rel="noreferrer"
                area-label="https://www.linkedin.com/company/pro.digi/"
                target="_blank"
                className="mr-23 md:mr-10 lg:mr-23"
              >
                <span className="icon icon-linkedin-green w-24  h-15 md:h-15 lg:h-24 inline-block" />
              </a>
              <a
                role="link"
                href="https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg"
                rel="noreferrer"
                area-label="https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg"
                target="_blank"
                className="mr-23 md:mr-10 lg:mr-23"
              >
                <span className="icon icon-youtube-green w-24 h-15 md:h-15 lg:h-24 inline-block" />
              </a>
              <a
                role="link"
                href="https://twitter.com/WeProdigi"
                rel="noreferrer"
                area-label="https://twitter.com/WeProdigi"
                target="_blank"
                className="mr-23 md:mr-10 lg:mr-23"
              >
                <span className="icon icon-twitter-green w-24 h-15 md:h-15 lg:h-24 inline-block" />
              </a>
            </div>

            <p className="md:text-14 lg:text-16 text-white mb-15">
              {' '}
              <span className="icon icon-email inline-block w-24 h-15 bg-left align-middle" />
              info@weprodigi.com
            </p>

            <Heading>Reviews on</Heading>

            <div className="flex items-center">
              <a
                href="https://g.page/prodigi-agency/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="flex pr-5 mr-5 leading-none border-white border-r"
              >
                <Image src="/images/icons/social/google.svg" width={60} height={19} layout="intrinsic" alt="google" />
                <span className={'hidden'}>Profile</span>
              </a>
              <a
                area-label={'profole'}
                role="link"
                href="https://clutch.co/profile/weprodigi-agency"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="flex pr-5 mr-5 leading-none border-white border-r"
              >
                <Image src="/images/icons/social/clutch.svg" width={60} height={17} layout="intrinsic" alt="clutch" />
                <span className={'hidden'}>clutch</span>
              </a>
              <a
                href="https://www.facebook.com/weprodigi/reviews/"
                role="link"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="flex pr-5 mr-5 leading-none"
              >
                <Image src="/images/icons/social/facebook.svg" width={74} height={19} layout="intrinsic" alt="facebook" />
                <span className={'hidden'}>Facebook</span>
              </a>
            </div>

            <p className="text-14 text-green uppercase font-bold mt-10 mb-10">We accept online payments</p>

            <div className="flex items-center">
              <Image src="/images/payment/visa.svg" width={64} height={20} layout="fixed" alt="visa" />
              <Image src="/images/payment/master.svg" width={145} height={33} layout="fixed" alt="master" />
              {props.country !== 'AM' && <Image src="/images/payment/bitcoin.svg" width={73} height={22} layout="fixed" alt="bitcoin" />}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white py-23">
        <div className="container">
          <div className="flex flex-col md:flex-row">
            <p className="relative md:inline-block hidden md:py-13 md:pr-13 md:mr-15 text-center md:text-left text-black sm:text-black md:text-gray-dark text-16 uppercase font-bold">
              Membership <span className="hidden md:block absolute top-0 right-0 w-2 h-full bg-gray-dark" />
            </p>

            <div className="flex-1 flex flex-row justify-center md:justify-between items-center flex-wrap md:flex-nowrap mt-5 md:mt-0">
              <img src="/images/membership/1.png" className="md:inline-block hidden" width="216" height="32" layout="intrinsic" alt="membership" />
              <img src="/images/membership/2.png" className="md:inline-block hidden" width="97" height="32" layout="intrinsic" alt="membership" />
              <img src="/images/membership/3.png" className="md:inline-block hidden" width="110" height="32" layout="intrinsic" alt="membership" />
              <img src="/images/membership/4.png" className="md:inline-block hidden" width="180" height="32" layout="intrinsic" alt="membership" />
              <img src="/images/membership/5.png" className="md:inline-block hidden" width="120" height="31" alt="membership" layout="intrinsic" />
              <img src="/images/membership/1-1.png" className="md:hidden mb-[16px]" width="305" height="47" alt="membership" layout="intrinsic" />
              <img src="/images/membership/1-2.png" className="md:hidden" width="116" height="40" alt="membership" layout="intrinsic" />
              <img src="/images/membership/1-3.png" className="md:hidden" width="131" height="37" alt="membership" layout="intrinsic" />
            </div>
          </div>
        </div>
      </div>

      <div className="container md:hidden">
        <p className="text-14 text-green text-center uppercase font-bold mt-10 mb-10">We accept online payments</p>

        <div className="flex flex-wrap items-center justify-center">
          <Image src="/images/payment/visa.svg" width={64} height={20} layout="fixed" alt="visa" />
          <Image src="/images/payment/master.svg" width={145} height={33} layout="fixed" alt="master" />
          {props.country !== 'AM' && <Image src="/images/payment/bitcoin.svg" width={73} height={22} layout="fixed" alt="bitcoin" />}
        </div>
      </div>

      <div className="container">
        <p className="text-gray-dark text-center text-14 py-10 px-[15px]">
          © {new Date().getFullYear()} | ProDigi - Digital Marketing Agency{' '}
          <a className="text-green underline" href="/privacy-policy">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
